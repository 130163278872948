<template>

  <!-- Idea Question -->
  <div class="idea-wrapper">

    <!-- Completed Overlay -->
    <div
      v-if="$store.state.idea.storyMode.common.completedSteps.ideaCompleted || !canEdit"
      class="completed-overlay"
      @click="onOpenDialogProcessCompleted"
    />
    <!-- /Completed Overlay -->

    <!-- Empty Card -->
    <div
      v-if="!text"
      class="question-card d-flex"
      :class="{ 'why': type === 'why', 'what': type === 'what', 'matching': matching, 'completed-card': $store.state.idea.storyMode.common.completedSteps.ideaCompleted || !canEdit }"
      @click="onClick"
    >
      <div class="d-flex flex-column justify-content-between flex-grow-1">
        <div>
          <!-- Title -->
          <h3>{{ title }}</h3>
          <!-- /Title -->

          <!-- Description -->
          <p>{{ description }}</p>
          <!-- /Description -->
        </div>

        <!-- Plus Button -->
        <el-button type="plus-round-xs" icon="el-icon-plus" />
        <!-- /Plus Button -->
      </div>
    </div>
    <!-- /Empty Card -->

    <!-- Card With Data -->
    <div
      v-else
      class="question-card filled story-mode-idea-question-filled"
      :class="{ 'why': type === 'why', 'what': type === 'what' }"
      @click="onClick"
    >

      <!-- Pen icon -->
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-if="canEdit && type === 'why'" v-bind:svg-inline="''" v-bind:class="'idea-pen'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#e53935" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" v-if="canEdit && type === 'what'" v-bind:svg-inline="''" v-bind:class="'idea-pen'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#0291ff" fill-rule="evenodd" d="M1.5 12.78l8.34-8.575 2.14 2.197-8.35 8.56H1.5v-2.183zM12.125 1.853l2.15 2.203-1.23 1.26L10.9 3.119l1.225-1.265zM.75 16.498h3.19c.2 0 .39-.082.53-.226L15.865 4.6a.777.777 0 000-1.086L12.655.225a.74.74 0 00-.53-.225.74.74 0 00-.53.225L.22 11.92a.796.796 0 00-.22.543v3.268c0 .425.335.768.75.768z"/></svg>
      <!-- /Pen icon -->

      <!-- Title -->
      <h3>{{ title }}</h3>
      <!-- /Title -->

      <!-- Divider -->
      <hr>
      <!-- /Divider -->

      <!-- Text -->
      <p class="clamped">
        <span class="inner-clamped" style="display:block" v-html="text" />
      </p>
      <!-- /Text -->

    </div>
    <!-- /Card With Data -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

  </div>
  <!-- /Idea Question -->

</template>

<script>
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'

export default {
  name: 'IdeaQuestion',

  components: {
    DialogProcessCompleted
  },

  mixins: [
    MixinProcessCompleted,
    MixinIdeaRoles
  ],

  props: {
    type: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    matching: {
      type: Boolean,
      default: true
    }
  },

  mounted () {
    this.$nextTick(() => {
      const whyCardText = document.querySelector('.why .clamped')
      const whyCardTextInner = document.querySelector('.why .clamped .inner-clamped')

      const whatCardText = document.querySelector('.what .clamped')
      const whatCardTextInner = document.querySelector('.what .clamped .inner-clamped')

      if (whyCardText) {
        if ((whyCardTextInner.offsetHeight - 20) > whyCardText.offsetHeight) {
          whyCardText.classList.add('scrollable')
        } else {
          whyCardText.classList.remove('scrollable')
        }
      }

      if (whatCardText) {
        if ((whatCardTextInner.offsetHeight - 20) > whatCardText.offsetHeight) {
          whatCardText.classList.add('scrollable')
        } else {
          whatCardText.classList.remove('scrollable')
        }
      }
    })
  },

  methods: {
    onClick () {
      if (this.canEdit) this.$emit('click')
    },

    onOpenDialogProcessCompleted () {
      if (this.canEdit) this.openedDialogProcessCompleted()
    }
  }
}
</script>
